/* ======================================================== */
main.desk {
  max-height: 100vh !important;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: white;
  position: absolute;
  transition: margin-left 325ms ease-out, width 325ms ease-out;

}

body{
  position: fixed;
  max-width: 100%;
  max-height: 100%;
}

div.desk {
  height: calc(100vh - 20px);
  margin: 10px;
  background-color: #26262F;
  border-radius: 10px;
  transition: opacity 1000ms;
}

/* ======================================================== */
div.desk-head {
  width: calc(100% - 50px);
  margin-left: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}

div.desk-head svg {
  height: 18px;
  flex-shrink: 0;
  display: inline-block;
  padding: 15px 0px 15px 15px;
}

div.desk-head-title {
  height: 1em;
  width: calc(100% - 30px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  color: #B0B0BF;
  padding-right: 10px;
  padding-left: 10px;
  letter-spacing: 1.4px;
}

div.desk-main {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
}

section.list {
  min-width: 180px;
  width: 180px;
  height: 100%;
  position: relative;
  margin-right: 0px;
}

/* section.list:nth-child(1):after {
  width: .5px;
  height: 95%;
  position: absolute;
  top: 2%;
  right: 0;
  content: "";
  background: #65657B;
} */

div.list-head {
  width: 100%;
  height: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1.list-head {
  padding: 0px 15px;
  font-weight: 500;
  font-size: 12.5px;
  text-align: center;
}

div.list-head svg {
  height: 18px;
  margin-left: 5px;
}

div.list-main {
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
}

div.list-fade {
  width: calc(100% - 30px);
  height: calc(100vh - 110px);
  position: absolute;
  pointer-events: none;
  z-index: 2;
  background: rgb(50, 50, 61);
  background: linear-gradient(0deg, rgba(38,38,47, 1) 0%, rgba(38,38,47, 0) 6%, rgba(38,38,47, 0) 98%, rgba(38,38,47, 1) 100%);
}

div.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;

}

div.list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img.list-img {
  width: 130px;
  height: 65px;
  pointer-events: none;
  object-fit: cover;
  animation: .15s blurIn;
  animation-timing-function: ease-in;
}

img.list-img-drag {
  height: 25px;
}

div.list-info {
  width: 160px;
  height: 20px;
  padding: 4px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
  text-transform: capitalize;
}

h2.list-info {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}

div.list-bookend {
  width: 70%;
  min-height: .5px;
  background-color: #65657B;
}

div.list-bookend:nth-child(1) {
  margin: 10px 0px 25px 0px;
}

div.list-bookend:nth-child(2) {
  margin: 25px 0px 10px 0px;
}

div.list-space {
  min-height: 50px;
  width: 100%;
}

p.list-placeholder {
  font-size: 12px;
  color: #C7C7D1;
  margin: 5px 0px 30px 0px;
}

/* ======================================================== */
form.new {
  width: 100%;
  background-color: #34343F;
  display: flex;
  border-radius: 10px;
}

form.new input[type='text'] {
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.2px;
  border: none;
  outline: none;
  vertical-align: bottom;
  background-color: #34343F;
  color: white;
}

/* ======================================================== */
section.view {
  flex-grow: 1;
  height: 100%;
}

/* section.view > div{
  margin-right: 1px;
  margin-bottom: 1px;
  border-radius: 10px;
  border: solid 1px #47475C;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  background-color: #2B2B34;
} */

div.view-head {
  width: 100%;
  /* height: 64px; */
  display: flex;
  flex-direction: column;
  margin: 0px 0px 12px 0px;
}

div.view-title {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.view-bar {
  /* width: calc(100vw - 510px); */
  width: calc(100% - 20px);

  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 14px;
  background-color: #34343F;
  margin: 0px 10px;
}

h3.view-title {
  padding: 0px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #DDDDE3;
  width: 100%;
  height: 1em;
}

h4.view-title {
  padding: 0px 15px;
  font-size: 11px;
  font-weight: 400;
  color: #C7C7D1;

}

div.view-main {
  height: calc(100% - 53px);
  width: 100%;
  cursor: default;
  position: relative;
  display: flex;
}

div.view-controls {
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 3;
  display: flex;
}

div.view-controls button{
  border: 2px solid rgba(255,255,255,0);
  background: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  padding: 6px;
  /* transition: all 300ms; */
}



div.view-img {
  display: flex;
  justify-content: center;
  /* width: calc(100% - 30px);
  max-height: calc(100vh - 380px);
  margin: 0px 0px 0px 20px; */
  /* z-index: 1; */
}

div.view-img > div {
  /* height: calc(100% - 10px) !important; */
  width: auto !important;
  height: auto !important;

  /* width: calc(100% - 10px) !important;
  height: auto !important; */

  /* width: calc(100% - 30px);
  max-height: calc(100vh - 380px);
  margin: 0px 0px 0px 20px; */
  /* z-index: 1; */
}

div.view-img *{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

}

.magnifier{
  display: block !important;
}

.magnifier-image{
  cursor: default !important;
  object-fit: contain !important;
}

div.view-img canvas {
  filter: brightness(1.3);
}

div.view-img img {
  filter: brightness(1.3);

  /* max-height: calc(100vh - 380px);
  display: block;
  margin: 0px auto;
  max-width: 100%; */
}


div.view-text {
  flex-grow: 1;
  z-index: 2;
  width: 100%;
  color: white;
  background-color: red;

  font-family: "Whitney Cond A", "Whitney Cond B";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6em;

  background-color: #2C2C37;
  border-radius: 10px;
}


div.view-text-field {
  /* width: calc(100vw - 441px); */
  flex-grow: 1;
  height: 100%;
  width: calc(100% - 10px);
  border-radius: 10px;
  margin: 5px;
  text-transform: none;
}

div.ResizePanel-module_ResizeHandleVertical__I0jId, div.ResizePanel-module_ResizeHandleHorizontal__PkS9u{
  display: none;
}

form.textalert{
  width: 100%;
  height: 100%;
}


form.textalert textarea {
  resize: none;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  margin: 5px 0px 5px 0px;
  border-radius: 5px;
  padding: 10px 15px;
  background-color: transparent;
  color: white;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .3px;
  font-family: "Whitney Cond A", "Whitney Cond B";
}


div.textalert-controls{
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  width: 300px;
  height: 30px;

}

div.textalert-controls button{
  background: rgba(73,73,91,.7);
  backdrop-filter: blur(3px);
}

button.textareasubmit{
  color: white;
  text-transform: uppercase;
  width: 120px;
  height: 22px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  letter-spacing: 3px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 600;

}

button.textareasubmit:focus{
  border: none;

}

button.resetbutton{
  background-color: #34343F;
  color: white;
  text-transform: uppercase;
  width: 120px;
  margin-right: 50px;
  height: 22px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  letter-spacing: 3px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 600;

}

button.resetbutton:focus{
  border: none;

}



div.view-text-fade {
  width: calc(100vw - 441px);
  height: 100px);
  min-height: 150px;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  background: rgb(50, 50, 61);
  background: linear-gradient(0deg, rgba(38,38,47, 1) 0%, rgba(38,38,47, 0) 0%, rgba(38,38,47, 0) 90%, rgba(38,38,47, 1) 97%);
}

/* ======================================================== */
div.acts {
  width: 90%;
  margin: 20px auto 0px auto;
}

div.act-head {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 11px;
}

div.act-main {
  height: 35px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  background-color: #34343F;
}

form.act {
  width: 100%;
  border-radius: 10px;
  display: flex;
  background-color: #34343F;
}

form.act input[type='text'] {
  width: calc(100% - 50px);
  margin-left: 15px;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  vertical-align: bottom;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.4px;
  background-color: #34343F;
  transition: all 250ms ease-in;
  color: white;
}

form.act input[type='submit'] {
  display: none;
  background-color: #34343F;
}

label.act {
  height: 35px;
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: none;
  background-color: #49495A;
}

label.act>svg {
  height: 25px;
  padding: 5px 10px;
  pointer-events: none;
}



::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;

}

/* Track */
::-webkit-scrollbar-track {
  background-color: #363644;
  border-radius: 10px;
  cursor: pointer;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #535365;
  border-radius: 10px;
  cursor: default !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #84849A;
}

/* ======================================================== */
