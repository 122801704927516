body {
    position: revert;
}

h3 {
    margin-bottom: 4px;
}

.progress {
    width: 400px;
    margin-top: 0px;
    overflow: scroll;
    height: 100%;
    background: #d9f2ff;
}