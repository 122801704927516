form.addtag {

  border-radius: 10px;
  display: flex;
  height: 100%;
  margin-left: 15px;
  padding: 0px;
}

form.addtag input[type='text'] {
  height: 100%;
  width: 300px;
  padding: 5px 12px;
  text-align: right;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  vertical-align: bottom;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.4px;
  background-color: #34343F;
  transition: all 250ms ease-in;
  color: white;
  border-radius: 10px;

}

/* form.addtag input[type='text']:focus {
  background-color: #49495A;
} */

form.addtag input[type='text']:hover {
  letter-spacing: 2px;

}
